body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4379F4 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#top-corner img {
  width: 100%;
  height: 100%;
}

.second-row-cell img {
  width: 100%;
  height: 100%;
}

@media (min-width: 1201px) {
  #first-row {
    height: 200px;
  }
  #top-corner {
    height: 200px;
  }
  #household-label {
    font-size: 173px;
    color: #fff;
    height: 200px;
    line-height: 200px;
  }

  #social-hq {
    color: #fff;
    font-size: 55px;
    text-align: center;
    margin-top: 5%;
    line-height: 60px;
  }

  #coming-soon {
    font-weight: bold;
    font-size: 70px;
    text-align: center;
    color: #fff;
  }
}


@media (min-width: 901px) and (max-width: 1200px) {
  #first-row {
    height: 120px;
  }
  #top-corner {
    height: 120px;
  }
  #household-label {
    font-size: 55px;
    color: #fff;
    height: 120px;
    line-height: 120px;
  }

  #social-hq {
    color: #fff;
    font-size: 26px;
    text-align: center;
    margin-top: 5%;
    line-height: 30px;
  }

  #coming-soon {
    font-weight: bold;
    font-size: 60px;
    text-align: center;
    color: #fff;
  }
}

@media (max-width: 900px) {
  #first-row {
    height: 120px;
  }
  #top-corner {
    height: 120px;
  }
  #household-label {
    font-size: 41px;
    color: #fff;
    height: 120px;
    line-height: 120px;
  }

  #social-hq {
    color: #fff;
    font-size: 26px;
    text-align: center;
    margin-top: 5%;
    line-height: 30px;
  }

  #coming-soon {
    font-weight: bold;
    font-size: 50px;
    text-align: center;
    color: #fff;
  }
}
